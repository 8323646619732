


















































































import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator';
import { BlokItemSlide, StoryblokAsset } from '../../types';
import MediaHandler from '@/components/utilities/media/MediaHandler';
import LinkManual from '@/components/utilities/links/LinkManual';
import IconHandler from '@/components/utilities/icons/IconHandler';

@Component({
  components: { MediaHandler, LinkManual, IconHandler }
})
export default class ItemSlideVideo extends Vue {
  @Prop() blok!: BlokItemSlide;
  @Prop() activeSlideIndex!: number;
  @Prop() slideIndex!: number;

  $refs!: { videoRef: HTMLVideoElement, videoMobileRef: HTMLVideoElement };
  icon: string = require('~/assets/icons/plus.svg');
  is_hovered: boolean = false;
  isPlaying: boolean = false;
  is_hoverbox_hovered: boolean = false;
  observer: IntersectionObserver | null = null;
  isReady: boolean = false;
  isVideoVisible: boolean = false;

  get poster (): StoryblokAsset|false {
    return this.blok?.poster ? this.blok.poster : false;
  }

  get posterMobile (): StoryblokAsset|false {
    return this.blok?.poster_mobile ? this.blok.poster_mobile : false;
  }

  get isCurrentlyActive (): boolean {
    return this.activeSlideIndex === this.slideIndex;
  }

  go_to_blogpost (): void {
    if (!this.blok.link) { return; }
    if (!this.blok.link.cached_url) { return; }
    this.$router.push(this.blok.link.cached_url);
  }

  setOverlayColor () {
    if (this.blok.overlay_color === 'none-fontdark') {
      const swiperNavigation = this.$parent.$el.querySelector('.ct-swiper-navigation');
      if (swiperNavigation) {
        swiperNavigation.classList.add('color-dark');
      }
    }
  }

  goToNextSlide () {
    this.$emit('slided', this.slideIndex + 1);
    this.$emit('activeAutoplay');
  }

  initIntersectionObserver () {
    const options = {
      root: null,
      threshold: 0.1
    };

    if (this.$refs.videoRef || this.$refs.videoMobileRef) {
      this.observer = new IntersectionObserver(async (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            try {
              if (this.$refs.videoRef && this.$refs.videoRef instanceof HTMLVideoElement) {
                await this.$refs.videoRef.play();
                this.isPlaying = true;
                this.isVideoVisible = true;
              }
              if (this.$refs.videoMobileRef && this.$refs.videoMobileRef instanceof HTMLVideoElement) {
                await this.$refs.videoMobileRef.play();
                this.isPlaying = true;
                this.isVideoVisible = true;
              }
            } catch (error) {
              console.error('Error playing video:', error);
            }
          } else {
            if (this.$refs.videoRef && this.$refs.videoRef instanceof HTMLVideoElement) {
              this.$refs.videoRef.pause();

              this.isPlaying = false;
              this.isVideoVisible = false;
            }
            if (this.$refs.videoMobileRef && this.$refs.videoMobileRef instanceof HTMLVideoElement) {
              this.$refs.videoMobileRef.pause();
              this.isPlaying = false;
              this.isVideoVisible = false;
            }
          }
        }
      }, options);

      if (this.$refs.videoRef) {
        this.observer.observe(this.$refs.videoRef);
      }
      if (this.$refs.videoMobileRef) {
        this.observer.observe(this.$refs.videoMobileRef);
      }
    }
  }

  showPosterAndFadeIn () {
    this.isVideoVisible = true;
  }

  breakpointUp (size: string): boolean {
    const breakpoints: { [key: string]: string } = {
      md: '(min-width: 768px)',
      lg: '(min-width: 1024px)'
    };
    return window.matchMedia(breakpoints[size]).matches;
  }

  breakpointDown (size: string): boolean {
    const breakpoints: { [key: string]: string } = {
      md: '(max-width: 767px)',
      lg: '(max-width: 1023px)'
    };
    return window.matchMedia(breakpoints[size]).matches;
  }

  @Watch('isCurrentlyActive')
  onIsCurrentlyActiveChanged (value: boolean) {
    const swiperNavigation = this.$parent.$el.querySelector('.ct-swiper-navigation');
    if (!swiperNavigation) { return; }
    if (value) {
      this.$emit('deactiveAutoplay');
      swiperNavigation.classList.add('color-dark');
    } else {
      swiperNavigation.classList.remove('color-dark');
    }
  }

  beforeMount () {
    this.isReady = true;
  }

  mounted () {
    this.$nextTick(() => {
      if (this.isCurrentlyActive) {
        this.setOverlayColor();
      }
      this.initIntersectionObserver();
    });
  }

  beforeDestroy () {
    if (this.observer) {
      this.observer.disconnect();
    }

    const swiperNavigation = this.$parent.$el.querySelector('.ct-swiper-navigation');
    if (swiperNavigation) {
      swiperNavigation.classList.remove('color-dark');
    }
  }
}
